<template>
  <transition-group tag="ul" :class="['el-upload-list', 'el-upload-list--' + listType]" name="el-list">
    <li
      v-for="file in files"
      :class="['el-upload-list__item']"
      :key="file.uid"
      tabindex="0"
      @keydown.delete="$emit('remove', file)"
      @focus="focusing = true"
      @blur="focusing = false"
      @click="focusing = false"
    >
      <slot :file="file">
        <img class="el-upload-list__item-thumbnail" @click="prev(file.url)" :src="file.url" />
        <i class="close-btn" @click="$emit('remove', file)">&times;</i>
      </slot>
    </li>
  </transition-group>
</template>
<script>
export default {
  name: 'ElUploadList',

  data() {
    return {
      focusing: false
    }
  },
  // components: { ElProgress },

  props: {
    files: {
      type: Array,
      default() {
        return []
      }
    },
    handlePreview: Function,
    listType: String
  },
  methods: {
    parsePercentage(val) {
      return parseInt(val, 10)
    },
    handleClick(file) {
      this.handlePreview && this.handlePreview(file)
    },
    prev(url) {
      this.previewImg(url)
    }
  }
}
</script>
