// 自定义验证
import { Validator } from 'vee-validate';

export default () => {
  Validator.extend('compare', (value, [otherValue]) => {
    return value === otherValue;
  }, {
    hasTarget: true
  });

  // 手机号验证
  Validator.extend('phone', {
    validate: value => {
      const regExp = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
      return regExp.test(value)
    }
  });

  // 价格
  Validator.extend('money', {
    validate: value => {
      const regExp = /^(?:0\.\d{0,1}[1-9]|(?!0)\d{1,6}(?:\.\d{0,1}[1-9])?)$/
      return regExp.test(value)
    }
  });

  // 地区验证
  Validator.extend('area', {
    validate: value => value
  });


}
